<template>
    <div id="app">
        <ebaTopButton v-show="showTopButton" />
        <div class="container">
            <div class="header">
                <ebaHeader />
            </div>
            <router-view />
            <div class="footer">
                <ebaFooter>
                    <template slot="promotionalOffer">
                        {{ promotionalText }}
                        <a id="TAndCLink" :href="offerTermsAndConditions" target="_blank">
                            Terms and Conditions
                        </a>
                        apply.
                    </template>
                </ebaFooter>
            </div>
        </div>
    </div>
</template>

<script>
import { get as config } from './scripts/common/config.js';
import EventTrackingHandler from './scripts/common/eventTrackingHandler.js';
import Store from "./vuex/store.js";
import authenticationHandler from './scripts/authenticationHandler.js';
export default {
    name: 'App',
    data() {
        return {
            promotionalText: `*New customers only. Free trial begins upon successful registration. A monthly fee of ${config(true).offerPrice} applies after your free trial. 
                You may cancel during your free trial without charge.`,
            offerTermsAndConditions: config().TermsAndConditions,
            idleTime: 0,
            maxIdleTime: 1800000, // 30 minutes in milliseconds
            idleTimer: null,
        };
    },
    methods: {
        authenticate(payload) {
            Store.commit("Authenticate", payload);
        },
        resetTimer() {
            clearTimeout(this.idleTimer);
            this.idleTime = 0;
            this.startTimer();
        },
        startTimer() {
            this.idleTimer = setTimeout(() => {
                authenticationHandler.UserActivityTimeout()
                    .then((response) => {
                        if (response.success) {
                            authenticationHandler.authenticate()
                                .then((resp) => {
                                    this.authenticate(resp);
                                    if (!resp.success) {
                                        window.location.href = "/account/login";
                                    }
                                });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }, this.maxIdleTime);
        },
        handleActivity() {
            this.resetTimer();
        }
    },
    computed: {
        showTopButton() {
            if (this.$route.meta.noMoveTop === true) {
                return false;
            }
            return true;
        }
    },
    mounted() {
        window.addEventListener('mousemove', this.handleActivity);
        window.addEventListener('keypress', this.handleActivity);
        window.addEventListener('scroll', this.handleActivity);
        window.addEventListener('click', this.handleActivity);
        this.startTimer();
    },
    beforeUnmount() {
        window.removeEventListener('mousemove', this.handleActivity);
        window.removeEventListener('keypress', this.handleActivity);
        window.removeEventListener('scroll', this.handleActivity);
        window.removeEventListener('click', this.handleActivity);
        clearTimeout(this.idleTimer);
    }
};

</script>

<style lang="less">
@import "components/common/assets/css/common";
</style>