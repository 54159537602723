const qa = {

    ApplicationId: '88E84135-F72B-4267-BEB3-6A2F2353B147',

    AntiForgeryAPI: 'https://qa-www.mybusinessprofile.com/api/Api.AntiForgery/api/antiforgery/tokens/',
    AntiForgeryCookieDomain: 'qa-www.mybusinessprofile.com',

    BusinessSearchAPI: 'https://qa-www.mybusinessprofile.com/api/BusinessSearch/api/business-target/',
    RegistrationAPI: 'https://qa-www.mybusinessprofile.com/api/MBPRegistration/api/',
    DirectorSearchAPI: 'https://qa-www.mybusinessprofile.com/api/DirectorSearch/api/officer/directorsByRegNo/',
    UraAPI: 'https://qa-www.mybusinessprofile.com/api/ura/api/ura/search',
    AuthenticateIiqAPI: "https://qa-www.mybusinessprofile.com/api/AuthenticateIIQ/api/Authentication/",
    EventTracking: "https://qa-www.mybusinessprofile.com/api/SessionTracking/Create",
    PinAPI: 'https://qa-www.mybusinessprofile.com/api/Pin/api/Pin/',
    QASApi: 'https://qa-www.mybusinessprofile.com/api/Api.Qas/api/address',
    AuthenticationEndpoint: 'https://qa-www.mybusinessprofile.com/Security/IsUserLoggedIn',
    LoginEndpoint: 'https://qa-www.mybusinessprofile.com/Account/LoginAsync',
    TermsAndConditions: 'https://qa-www.mybusinessprofile.com/Docs/MBP-Terms-and-Conditions-040124.pdf',
    PromotionalTermsAndConditions: 'https://qa-www.mybusinessprofile.com/Docs/MBP_T&Cs.pdf',
    PrivacyPolicy: 'https://www.experian.co.uk/legal/privacy-statement.html',
    StatutoryReport: 'https://www.mybusinessprofile.com/statutory',
    ThirdPartyServices: 'https://www.experian.co.uk/legal/privacy-statement.html',
    CancellationEndPoint: 'https://qa-www.mybusinessprofile.com/cancellation',
    WebFilingLink : "https://idam-ui.company-information.service.gov.uk/",
    UserActivityEndpoint: 'https://qa-www.mybusinessprofile.com/Security/',
    UraPurpose: 'MBP QA Testing Verification',

    GeneralEnquiryEmail: 'mbp.info@experian.com',
    GeneralEnquirySubject: 'MBP - General Enquiry',
    GeneralEbeEnquiryEmail: 'ebe.info@experian.com',

    NonLimReport: 'https://www.experian.co.uk/assets/consumer/stat-report/Stat-credit-report-app-non-limited.pdf',

    ProductCode: 'OM2499',
    ProductVariantSku: 'MBP-63BZXN',

    footerDetails: {
        contactUsText: 'Sales & Help',
        supportPhoneNumber: '0345 045 8081',
        supportEmailAddress: 'mbp.info@experian.com',
        callChargeText: '(Calls charged at standard rate plus your phone company\'s access charge - please see FAQ\'s)',

        footerLinks: [
            {
                routeName: 'ViewTermsAndConditions',
                text: 'Terms & Conditions'
            },
            {
                routeName: 'Privacy',
                text: 'Privacy & Cookies'
            },
            {
                routeName: 'ReportAProblem',
                text: 'Report a Problem'
            },
            {
                routeName: 'FAQs',
                text: 'Help & FAQ\'s'
            },
            {
                routeName: 'StatutoryReport',
                text: 'Statutory Report'
            }
        ]
    },

    registrationOrder: [
        'ContactDetails',
        'Password',
        'MemorableWord',
        'SecurityQuestions',
        'PersonalDetails',
        'QASSelect',
        'PreviousAddress',
        'TermsAndConditions',
        'CommsAndMarketing',
        'BillingDetails'
    ],

    StripePublishableKey: 'pk_test_KAYPBL4p9ZmJLymrc37nWujI'
};

module.exports = qa;
